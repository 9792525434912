import axios from "axios";
import { getServer } from "./utilities";
import moment from "moment/moment";
import dayjs from "dayjs";
import haversine from "haversine";

function calculeExtraSalida(userData, horaEntrada){
    const date = new Date();
    const dayOfWeek = date.getDay();
    let tiempo = 0;
    let horario = false;

    let final = moment().set("hour", userData.final.split(":")[0]).set("minute", userData.final.split(":")[1]);                
    let actual = moment().set("hour", date.getHours()).set("minute", date.getMinutes());
    let entrada = dayjs(horaEntrada);

    // si salio 5 minutos despues del final, registrar extras
    final.add(5, "minute");
    if((actual.isAfter(final) && enHorario(dayOfWeek, userData, horario)) && 
        (entrada.isBefore(final))){
        tiempo = parseFloat(actual.diff(final, 'minutes')/60);
    }else if((actual.isAfter(final) && enHorario(dayOfWeek, userData, horario)) && 
        (entrada.isAfter(final))){
        tiempo = parseFloat(entrada.diff(actual, 'minutes')/60 * -1);
    }

    if(!enHorario(dayOfWeek, userData, horario)){
        tiempo = parseFloat(entrada.diff(actual, 'minutes')/60 * -1);
    }

    return tiempo;
}

function tardiaEntrada(userData, entrada){
    const date = new Date();
    const dayOfWeek = date.getDay();
    let tardia = false;
    let extra = false;
    let horario = false;

    let inicio = moment().set("hour", userData.inicio.split(":")[0]).set("minute", userData.inicio.split(":")[1]);                
    let final = moment().set("hour", userData.final.split(":")[0]).set("minute", userData.final.split(":")[1]);                
    let actual = moment().set("hour", date.getHours()).set("minute", date.getMinutes());

    inicio.add(5, "minute");

    // si entrada es despues del inicio
    tardia = entrada && actual.isAfter(inicio);

    final.subtract(5, "m");
    // si salida es antes del final
    if(!tardia){
        tardia = !entrada && actual.isBefore(final);
    }

    horario = enHorario(dayOfWeek, userData, horario);

    if(horario){
        if(entrada){
            return tardia;
        }else{
            return extra;
        }
    }else {
        return false;
    }
}

function enHorario(dayOfWeek, userData, horario) {
    if (dayOfWeek === 0 && userData.domingo === 1) {
        horario = true;
    } else if (dayOfWeek === 1 && parseInt(userData.lunes) === 1) {
        horario = true;
    } else if (dayOfWeek === 2 && parseInt(userData.martes) === 1) {
        horario = true;
    } else if (dayOfWeek === 3 && parseInt(userData.miercoles) === 1) {
        horario = true;
    } else if (dayOfWeek === 4 && parseInt(userData.jueves) === 1) {
        horario = true;
    } else if (dayOfWeek === 5 && parseInt(userData.viernes) === 1) {
        horario = true;
    } else if (dayOfWeek === 6 && parseInt(userData.sabado) === 1) {
        horario = true;
    }
    return horario;
}

export function registrarEntrada(setMensajeEntrada, setIconAcceso, entrada, setEntrada, time, entradaId, setEntradaId, setErrorMessage, 
    userData, setTardia, setEntradaEnable, parametros, mac, horaEntrada, setHoraEntrada) {
    var geolocation = require('geolocation');
    const usuario = sessionStorage.getItem("usuario");
    const token = sessionStorage.getItem("token");
    let tiempo = 0;

    setErrorMessage('');

    geolocation.getCurrentPosition(function (err, position) {
        if (err) throw err

        let data = { usuario, token, "MACIP": mac};
        let coordenasFuera = true;

        parametros.map(coordenada => {            
            if(coordenasFuera){
                const x = coordenada.valor.split(",");
                const start  = { "latitude": parseFloat(x[0]), "longitude": parseFloat(x[1]) };
                const end = { "latitude": position.coords.latitude, "longitude": position.coords.longitude };

                const distance = haversine(start, end, {unit: 'meter'}) - position.coords.accuracy;
                if(distance < 100){
                    coordenasFuera = false;
                }
            }
        });

        if (entrada) {
            let tardia = tardiaEntrada(userData, true);
            setTardia(tardia? 'visible':'hidden');
            setEntradaEnable(!tardia);
            setHoraEntrada(data.entrada);

            data = { ...data, "latitud": position.coords.latitude, "longitud": position.coords.longitude,
                 "action": "entrada", "tardia": tardia, "rangofuera": coordenasFuera, "hextra": tiempo, "entrada": time}
            axios.post(getServer() + `/acceso.php`, data)
                .then(res => {
                    setEntradaId(res.data);

                    setEntrada(false);
                    setMensajeEntrada('Entrada registrada: ' + dayjs(time).format('DD/MM/YYYY HH:mm'));
                    setIconAcceso(tardia? 'tarde.png' : 'clockout.jpg');
                });
        } else {
            let tardia = tardiaEntrada(userData, false);
            setTardia(tardia? 'visible':'hidden');

            if(!tardia){
                tiempo = calculeExtraSalida(userData, horaEntrada)
            }

            data = { ...data, "latitudSalida": position.coords.latitude, "longitudSalida": position.coords.longitude, 
                "salida": time, "id": entradaId, "action": "salida", "tardia": tardia, "hextra": tiempo, "rangofuera2": coordenasFuera} 

            axios.post(getServer() + `/acceso.php`, data)
                .then(res => {
                    setEntradaId(res.data);

                    setEntrada(true);
                    setMensajeEntrada('Marcar Entrada');
                    setIconAcceso('clockin.png')
                });
        }
    });

}