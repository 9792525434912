import React, { useEffect, useState } from 'react';
import { getServer } from '../actions/utilities';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function MantenimientoEmpleados() {
  const [data, setData] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const [mensaje, setMensaje] = useState('');
  const [changePwd, setChangePwd] = useState(false);
  const [currentUser, setCurrentTecnico] = useState({
    id: '',
    username: '',
    idhorario: '',
    inicio: '',
    password: '',
    nombre: '',
    disponibles: '',
  });
  const navigate = useNavigate();

  if (sessionStorage.getItem('token') === null || sessionStorage.getItem('token') === undefined) {
    navigate("/");
}

  useEffect(() => {
    fetchUsers();
    fetchHorarios();
  }, []);

  const fetchHorarios = () => {
    axios.get(getServer() + '/horarios.php')
      .then(function (response) {
        setHorarios(response.data);
      })
      .catch(function (error) {
      })
      .then(function () {
        // always executed
      });
  }

  const fetchUsers = () => {
    axios.get(getServer() + '/user.php')
      .then(function (response) {
        setData(response.data);
      })
      .catch(function (error) {
      })
      .then(function () {
        // always executed
      });
  }

  const clearForm = event => {
    setCurrentTecnico({
      id: '',
      username: '',
      idhorario: '',
      password: '',
      nombre: '',
      disponibles: ''
    });
    setMensaje("");
  }

  const getHorarioNombre = (id) => {
    const h = horarios.filter(x => x.idhorario === id);
    return h[0]?.nombre;
  }

  const viewUser = (event, id) => {
    const c = data.filter(obj => obj.id === id);
    setMensaje("");
    setChangePwd(false);

    if (c.length > 0) {
      c[0].password = '';
      setCurrentTecnico(c[0]);
    }
  };

  const handleInputChange = (event) => {
    setMensaje("");
    const target = event.target;
    const value = target.value;

    switch (target.id) {
      case 'nombre':
        currentUser.nombre = value;
        break;
      case 'username':
        currentUser.username = value;
        break;
      case 'idhorario':
        currentUser.idhorario = value;
        break;
      case 'inicio':
        currentUser.inicio = value;
        break;
      case 'password':
        currentUser.password = value;
        setChangePwd(true);
        break;
      case 'disponibles':
        currentUser.disponibles = value;
        break;
      default:
        break;
    }

    setCurrentTecnico(currentUser);

    const newData = data.map(obj => {
      if (obj.id === currentUser.id) {
        obj = currentUser;
      }

      return obj;
    });

    setData(newData);
  };

  const deleteUser = (event, id) => {
    const postData = {
      action: 'delete',
      data: id
    };

    axios.post(getServer() + '/user.php', postData)
      .then(function (response) {
        clearForm();
        fetchUsers();
        setMensaje("Datos eliminados existosamente !!!");
      })
      .catch(function (error) {
        setMensaje("Error eliminando datos ");
      })
      .then(function () {
        // always executed
      });
  }

  const saveUser = event => {
    event.preventDefault();
    const postData = {
      action: '',
      data: {}
    };

    postData.action = (currentUser.id === undefined || currentUser.id === "") ? 'save' : 'update';
    postData.setPwd = changePwd;
    postData.data = currentUser;

    axios.post(getServer() + '/user.php', postData, { timeout: 2000 })
      .then(function (response) {
        clearForm();
        fetchUsers();
        setMensaje("Datos guardados existosamente !!!");
      })
      .catch(function (error) {
        setMensaje("Error guardando datos ");
      })
      .then(function () {
        // always executed
      });
  }

  return (
    <div className="container-lg ">
      <div className="row" style={{ margin: 50 }}>
        <div className="col align-self-center text-center">
          <h3>Mantenimiento de Técnicos</h3>
        </div>
      </div>
      <form onSubmit={saveUser}>
        <div className="row">
          <div className="col">
            <div className="mb-3" >
              <label className="form-label">Nombre y Apellidos</label>
              <input type="text" className="form-control" id="nombre" value={currentUser.nombre} required onChange={handleInputChange} />
            </div>
            <div className="mb-3" >
              <label className="form-label">Nombre de usuario</label>
              <input type="text" className="form-control" id="username" value={currentUser.username} onChange={handleInputChange} />
            </div>
            <div className="mb-3" >
              <label className="form-label">Contraseña</label>
              <input type="password" className="form-control" id="password" value={currentUser.password} onChange={handleInputChange} />
            </div>
          </div>
          <div className="col">
            <div className="mb-3" >
              <label className="form-label">Horario</label>
              <select id='idhorario' className='form-select' value={currentUser.idhorario} onChange={handleInputChange}>
                <option disabled={true} value="">Escoja un horario</option>
                {
                  horarios.map(({ idhorario, nombre, index }) => (
                    <option key={index} value={idhorario}>{nombre}</option>
                  ))
                }
              </select>            </div>
            <div className="mb-3" >
              <label className="form-label">Vacaciones disponibles</label>
              <input type="number" className="form-control" id="disponibles" value={currentUser.disponibles} onChange={handleInputChange} />
            </div>
            <div className="mb-3" align="center">
              <button type="submit" className="btn"><img src='/save.png' alt='save button' className='rounded' width="50px" /></button>
              <button type="button" className="btn"><img src='/limpiar.png' className='rounded' width="50px" alt='Limpiar formulario' title='Limpiar' onClick={clearForm} /></button>
            </div>
          </div>
        </div>
      </form>
      <div className="col align-self-center text-center alert alert-secondary">
        <label>{mensaje}</label>
      </div>
      <div className="row" style={{ margin: 50 }}>
        <div className="col align-self-center text-center">
          <h3>Técnicos Registrados</h3>
        </div>
      </div>
      <div className='row border border-sucess text-center' >
        <div id='header' className='col border border-sucess'>Acción</div>
        <div id='header' className='col border border-sucess'>Nombre</div>
        <div id='header' className='col border border-sucess'>Vacaciones</div>
        <div id='header' className='col border border-sucess'>Horario</div>
      </div>
      {
        data.map(({ id, username, role, nombre, disponibles, idhorario }) => (
          <div className='row'>
            <div className='col border border-sucess align-self-center text-center'>
              <a
                href='#'
                onClick={event => viewUser(event, id)}
              >
                <img src='edit.png' alt='edit button' width="25px" style={{ margin: 5 }}></img>
              </a>
              <a
                href='#'
                onClick={event => deleteUser(event, id)}
              >
                <img src='delete.png' alt='delete button' width="25px"></img>
              </a>
            </div>
            <div className='col border border-sucess'>{nombre}</div>
            <div className='col border border-sucess'>{disponibles}</div>
            <div className='col border border-sucess'>{getHorarioNombre(idhorario)}</div>
          </div>
        ))
      }
      <div className='align-self-center text-center'>
        <button type="button" className="btn">
          <a href='#' onClick={() => navigate("/")}>
            <img src='/back.png' className='rounded' width="50px" title='Regresar al reporte' alt='' />
          </a>
        </button>
      </div>
      <div className='row' style={{ margin: 50 }}></div>
    </div>
  );
}