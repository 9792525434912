import axios from "axios";
import { useEffect, useState } from "react";
import { getServer } from "../actions/utilities";
import { useNavigate } from "react-router-dom";
import XLSX from 'sheetjs-style';
import FileSaver from 'file-saver';
import Dashboard from "./Dashboard";

export default function ReporteVacaciones() {

    const [data, setData] = useState([]);
    const navigate = useNavigate();

    if (sessionStorage.getItem('token') === null || sessionStorage.getItem('token') === undefined) {
        navigate("/");
    }

    const exportToExcel = async (excelData) => {
        const filterData = excelData.map((x) => ({Tecnico: x.nombre, Vacaciones: x.disponibles}));

        console.log(filterData);

        const ws = XLSX.utils.json_to_sheet(filterData);
        const wb = { Sheets: {'data': ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8' });
        FileSaver.saveAs(data, 'Reporte Vacaciones.xlsx');
    }

    useEffect(() => {
        fetchReporte();
    }, []);

    const fetchReporte = () => {
        axios.get(getServer() + "/user.php")
            .then(function (response) {
                setData(response.data);
            })
            .catch(function (error) {
            })
            .then(function () {
                // always executed
            });
    }

    return (
        <div className="container-lg ">
            <div className="row" style={{ margin: 50 }}>
                <div className="col align-self-center text-center">
                    <h3>Reporte de Vacaciones</h3>
                </div>
            </div>
            <hr></hr>
            <table className="table table-responsive table-striped">
                <thead className='row border border-sucess text-center' >
                    <th id='header' className='col border border-sucess'>Nombre</th>
                    <th id='header' className='col border border-sucess'>Días Disponibles</th>
                </thead>
                <tbody>
                    {
                        data.map(({ nombre, disponibles }) => (
                            <tr className='row'>
                                <td className='col border border-sucess'>{nombre}</td>
                                <td className='col border border-sucess'>{disponibles}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <div className='align-self-center text-center'>
                <button type="submit" className="btn"><img src='/save.png' alt="save button" className='rounded' width="50px" onClick={() => exportToExcel(data)}/></button>
                <button type="button" className="btn">
                    <a href='#' onClick={() => navigate("/")}>
                        <img src='/back.png' className='rounded' width="50px" title='Regresar al reporte' alt='' />
                    </a>
                </button>
            </div>
        </div>

    );
}
