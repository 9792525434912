import axios from "axios";
import { useEffect, useState } from "react";
import { getServer } from "../actions/utilities";
import { useNavigate } from 'react-router-dom';

export default function MantenimientoParametros() {

    const [data, setData] = useState([]);
    const [mensaje, setMensaje] = useState('');
    const [current, setCurrent] = useState({});
    const navigate = useNavigate();

    if (sessionStorage.getItem('token') === null || sessionStorage.getItem('token') === undefined) {
        navigate("/");
    }

    useEffect(() => {
        fetchParametros();
    }, []);

    const fetchParametros = async () => {
        axios.get(getServer() + '/parametros.php')
            .then(function (response) {
                setData(response.data);
            })
            .catch(function (error) {
            })
            .then(function () {
                // always executed
            });
    }

    const clearForm = (event) => {
        setCurrent({id: 0, "nombre": "", "valor": ""});
        setMensaje("");
    }

    const verCoordenada = (event, id) => {
        const c = data.filter(obj => obj.id === id);
        setMensaje("");

        if (c.length > 0) {
            setCurrent(c[0]);
        }
    }

    const borrarCoordenada = (event, id) => {
        event.preventDefault();
        const postData = {
            accion: "borrar",
            data: id
        };

        axios.post(getServer() + '/parametros.php', postData, { timeout: 2000 })
            .then(function (response) {
                fetchParametros();
                setMensaje("Datos eliminados existosamente !!!");
            })
            .catch(function (error) {
                setMensaje("Error guardando datos ");
            })
            .then(function () {
                // always executed
            });
    }

    const saveUser = event => {
        event.preventDefault();
        const postData = {
            accion: "",
            data: current
        };

        if(current.id === undefined){
            postData.accion = "insertar";
        }else {
            postData.accion = "actualizar";
        }

        axios.post(getServer() + '/parametros.php', postData, { timeout: 2000 })
            .then(function (response) {
                fetchParametros();
                setMensaje("Datos guardados existosamente !!!");
            })
            .catch(function (error) {
                setMensaje("Error guardando datos ");
            })
            .then(function () {
                // always executed
            });
    }

    const handleInputC1Change = (event) => {
        setMensaje("");
        const id = event.target.id;
        let value = event.target.value;

        setCurrent({ ...current, [id]: value });
    };


    return (
        <div className="container-lg ">
            <div className="row" style={{ margin: 50 }}>
                <div className="col align-self-center text-center">
                    <h3>Mantenimiento de Coordenadas</h3>
                </div>
            </div>
            <div className="row">
                <form onSubmit={saveUser}>
                <div className="col">
                    <div className="mb-3" >
                        <label className="form-label">Nombre</label>
                        <input type="text" className="form-control" required id="nombre" value={current.nombre} onChange={handleInputC1Change} />
                    </div>
                </div>
                <div className="col">
                    <div className="mb-3" >
                        <label className="form-label">Coordenadas</label>
                        <input type="text" className="form-control" required id="valor" value={current.valor} onChange={handleInputC1Change} />
                    </div>
                </div>
                <div className="mb-3" align="center">
                    <button type="submit" className="btn"><img alt='guardar formulario' src='/save.png' className='rounded' width="50px"/></button>
                    <button type="button" className="btn"><img src='/limpiar.png' className='rounded' width="50px" alt='Limpiar formulario' title='Limpiar' onClick={clearForm} /></button>
                </div>
                </form>
            </div>
            <hr></hr>
            <table className="table table-responsive table-striped">
                <thead className='row border border-sucess text-center' >
                    <th id='header' className='col border border-sucess'>Nombre</th>
                    <th id='header' className='col border border-sucess'>Coordenadas</th>
                    <th id='header' className='col border border-sucess'>Acciones</th>
                </thead>
                <tbody>
                    {
                        data.map(({ id, nombre, valor }) => (
                            <tr className='row'>
                                <td className='col border border-sucess'>{nombre}</td>
                                <td className='col border border-sucess'>{valor}</td>
                                <div className='col border border-sucess align-self-center text-center'>
                                    <a
                                        href='#'
                                        onClick={event => verCoordenada(event, id)}
                                    >
                                        <img src='edit.png' alt='edit button' width="25px" style={{ margin: 5 }}></img>
                                    </a>
                                    <a
                                        href='#'
                                        onClick={event => borrarCoordenada(event, id)}
                                    >
                                        <img src='delete.png' alt="delete button" width="25px"></img>
                                    </a>
                                </div>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <div className="col align-self-center text-center alert alert-secondary">
                <label>{mensaje}</label>
            </div>
            <div className="mb-3" align="center">
                <a href='#' onClick={() => navigate("/")}>
                    <img src='/back.png' className='rounded' width="50px" title='Regresar al reporte' alt='' />
                </a>
            </div>

        </div>
    );
}