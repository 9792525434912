import React, { Component } from 'react';

class Selfie extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
  }

  componentDidMount() {
    this.startCamera();
  }

  startCamera() {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        this.videoRef.current.srcObject = stream;
      })
      .catch((error) => {
        console.error('Error al acceder a la cámara: ', error);
      });
  }

  takeSelfie() {
    const canvas = document.createElement('canvas');
    const video = this.videoRef.current;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Aquí puedes guardar la imagen o mostrarla en la interfaz de usuario.
    canvas.toBlob((blob) => {
        // Llamar a la función saveImage para guardar la imagen
        this.saveImage(blob);
      }, 'image/jpeg'); 
  }

  saveImage(blob) {
    const a = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'selfie.jpg'; // Nombre del archivo a descargar
    a.click();
    window.URL.revokeObjectURL(url);
  }

  render() {
    return (
      <div>
        <video ref={this.videoRef} autoPlay width={150}/>
      </div>
    );
  }
}

export default Selfie;
