import axios from "axios";
import { useEffect, useState } from "react";
import { getServer } from "../actions/utilities";
import { useNavigate } from 'react-router-dom';

export default function AprobarVacaciones() {
    const [data, setData] = useState([]);
    const [mensaje, setMensaje] = useState([]);
    const navigate = useNavigate();

    if (sessionStorage.getItem('token') === null || sessionStorage.getItem('token') === undefined) {
        navigate("/");
    }

    useEffect(() => {
        fetchVacaciones();
    }, []);

    const aprobar = (id, fecha_solicitada, usuario) => {
        const postData = {
            action: "aprobar",
            id: id,
            fecha: fecha_solicitada,
            userid: usuario,
        }

        axios.post(getServer()+"/vacaciones.php", postData)
            .then(function (response){
                fetchVacaciones();
            })
            .catch(function(error){

            })
    }

    const denegar = (id, cantidad, usuario, disponibles, fecha_solicitada) => {
        const postData = {
            action: "denegar",
            id: id,
            cantidad: parseInt(cantidad) + parseInt(disponibles),
            userid: usuario,
            fecha: fecha_solicitada
        }

        axios.post(getServer()+"/vacaciones.php", postData)
            .then(function (response){
                fetchVacaciones();
            })
            .catch(function(error){

            })
    }

    const fetchVacaciones = () => {
        const postData = {
            action: 'pendientes'
        };

        axios.post(getServer() + '/vacaciones.php', postData)
            .then(function (response) {
                setData(response.data);
            })
            .catch(function (error) {
            })
            .then(function () {
                // always executed
            });
    }

    return (
        <div className="container-lg ">
            <div className="row" style={{ margin: 50 }}>
                <div className="col align-self-center text-center">
                    <h3>Aprobar Vacaciones</h3>
                </div>
            </div>
            <table className="table table-responsive table-striped">
                <thead className='row border border-sucess text-center' >
                    <th id='header' className='col border border-sucess'>Nombre</th>
                    <th id='header' className='col border border-sucess'>Fecha de solicitud</th>
                    <th id='header' className='col border border-sucess'>Fecha solicitada</th>
                    <th id='header' className='col border border-sucess'>Estado</th>
                    <th id='header' className='col border border-sucess'>Cantidad</th>
                    <th id='header' className='col border border-sucess'>Acciones</th>
                </thead>
                <tbody>
                    {
                        data.map(({ idvacaciones, fecha_actual, fecha_solicitada, cantidad, nombre, aprobado, usuario, disponibles }) => (
                            <tr className='row'>
                                <td className='col border border-sucess'>{nombre}</td>
                                <td className='col border border-sucess'>{fecha_actual}</td>
                                <td className='col border border-sucess'>{fecha_solicitada}</td>
                                <td className='col border border-sucess'>{
                                    (aprobado === 0 || aprobado === '0')? 
                                        <strong style={{ color: 'green' }}>Pendiente</strong>:
                                        (
                                            (aprobado === 1 || aprobado === '1')? 
                                                <strong>Aprobada</strong> : 
                                                (
                                                    (aprobado === 2  || aprobado === '2') ? <strong style={{ color: 'red' }}>Denegada</strong> :
                                                    null
                                                )
                                        )
                                    }
                                </td>
                                <td className='col border border-sucess'>{cantidad}</td>
                                    {(aprobado===0 || aprobado==='0')?
                                        <td className='col border border-sucess text-center'>
                                        
                                            <a href="#" disable>
                                                <img src="/check.png" width="25" style={{ marginRight: 15 }} onClick={()=>aprobar(idvacaciones, fecha_solicitada,  usuario)}/>
                                            </a>
                                            <a href="#">
                                                <img src="/x.png" width="22" onClick={()=>denegar(idvacaciones, cantidad, usuario, disponibles, fecha_solicitada)}/>
                                            </a>
                                        </td>
                                        : <td className='col border border-sucess text-center'>-</td>
                                    }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <div className='align-self-center text-center'>
                <button type="button" className="btn">
                    <a href='#' onClick={() => navigate("/")}>
                        <img src='/back.png' className='rounded' width="50px" title='Regresar al reporte' alt='' />
                    </a>
                </button>
            </div>
        </div>
    );
}