import LoginPage from "./LoginPage";
import DashboardAdmin from "./DashboardAdmin";
import DashboardTecnico from "./DashboardTecnico";
import React from "react";

const Dashboard = () => {

    if (sessionStorage.getItem('token') === null) {
        return <LoginPage />
    }

    if (sessionStorage.getItem('role') === '1') {
        return (
            <DashboardAdmin />
        );
    } else if (sessionStorage.getItem('role') === '2') {
        return (
            <DashboardTecnico />
        );
    };
}

export default Dashboard;