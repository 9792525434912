

import { Container } from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import LoginPage from "./LoginPage";
import { registrarEntrada } from "../actions/acceso";
import { solicitarVacaciones } from "../actions/vacaciones";
import DatePicker from "react-datepicker";
import Selfie from "./Selfie";

import { getServer, logout } from "../actions/utilities";

import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import dayjs from "dayjs";

const DashboardTecnico = () => {
    const [mensajeEntrada, setMensajeEntrada] = useState('Marcar entrada');
    const [entrada, setEntrada] = useState(true);
    const [iconAcceso, setIconAcceso] = useState('/clockin.png');
    const [diasDisponibles, setDiasDisponibles] = useState(sessionStorage.getItem('disp'));
    const [entradaId, setEntradaId] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const [title, setTitle] = useState((sessionStorage.getItem('token') !== null) ? <h1 style={{ textAlign: "center" }}>Sistema de Acceso - Solutecsa</h1> : '');
    const [time, setTime] = React.useState('');
    const [vacas, setVacas] = React.useState('hidden');
    const [solicitar, setSolicitar] = React.useState('visible');
    const [numero, setNumero] = React.useState(1);
    const [userData, setUserData] = React.useState();
    const [tardia, setTardia] = React.useState('hidden');
    const [entradaEnable, setEntradaEnable] = React.useState(true);
    const [justificacion, setJustificacion] = React.useState('Enfermedad');
    const [parametros, setParametros] = React.useState();
    const [mac, setMac] = React.useState();
    const [horaEntrada, setHoraEntrada] = React.useState();
    const [foto, setFoto] = React.useState();

    const cambieNumero = (e) => {
        let value = e.target.value;
        if (value === '') {
            value = 0;
        } 
        value = parseInt(value)

        if ((value <= diasDisponibles && value >= 0) || value === '') {
            setNumero(value);
        }
    }

    const changeJusti = (e) => {
        setJustificacion(e.target.value);
    }

    const updateJustificacion = (ev) => {
        
        const postData = { 'justificacion': justificacion, 'action': 'justificacion', 'id': entradaId };
        axios.post(getServer() + '/acceso.php', postData)
            .then(res => {
                setTardia('hidden');
                setEntradaEnable('visible');
            })
            .catch(err => {

            });
    }

    const cuantosDias = (tipo) => {
        if (tipo === 1) {
            setVacas('visible');
            setSolicitar('hidden');
        } else {
            setVacas('hidden');
            setSolicitar('visible');
        }
    }

    useEffect(() => {
        const userId = sessionStorage.getItem('usuario');

        axios.get(getServer() + `/user.php?id=${userId}`,)
            .then(res => {
                const data = res.data[0];
                setUserData(data);
            })
            .catch(error => { });

        axios.get(getServer() + '/parametros.php')
            .then(res => {
                setParametros(res.data);;
            })
            .catch(error => { });
    }, []);

    useEffect(() => {
        const userId = sessionStorage.getItem('usuario');

        axios.get(getServer() + `/user.php?id=${userId}&entrada=true`,)
            .then(res => {
                const data = res.data;

                if (data !== undefined && data.length !== 0) {
                    const length = data.length;
                    const acceso = data[length - 1];

                    setEntrada(false);
                    setIconAcceso('clockout.jpg');
                    setMensajeEntrada("Entrada registrada: " + dayjs(acceso.entrada).format('DD/MM/YYYY HH:mm:ss'));
                    setEntradaId(acceso.idacceso);
                    setHoraEntrada(acceso.entrada);
                }
            })
            .catch(error => { });
    }, []);

    useEffect(() => {
        const userId = sessionStorage.getItem('usuario');

        axios.get(getServer() + `/mensaje.php?id=${userId}`,)
            .then(res => {
                const data = res.data;

                if (data !== undefined && data.length !== 0) {
                    const length = data.length;
                    const mensaje = data[length - 1];

                    setErrorMessage(mensaje.mensaje);
                }
            })
            .catch(error => { });
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    if (sessionStorage.getItem('token') === null) {
        return <LoginPage />;
    }

    return (
        <Container className="container-fluid justify-content-md-center" >
            {title}
            <br></br>
            <div style={{ textAlign: "center" }}>
                <span><h3> {dayjs(time).format('DD/MM/YYYY HH:mm:ss')}</h3></span>
            </div>
            <br></br><br></br>
            <div style={{ textAlign: "center" }}>
                <span><strong>{mensajeEntrada}</strong></span>
            </div>
            <div style={{ textAlign: "center", visibility: entradaEnable }}>

                <button type="button" disabled={!entradaEnable} className="btn btn-link" onClick={() => { 
                        registrarEntrada(setMensajeEntrada, setIconAcceso, entrada, setEntrada, time, entradaId, setEntradaId, setErrorMessage, 
                        userData, setTardia, setEntradaEnable, parametros, mac, horaEntrada, setHoraEntrada) }}>
                    <img src={iconAcceso} alt="acceso" width={150} />
                </button>
            </div>
            <div style={{ textAlign: "center", visibility: tardia }} >
                <label><strong>Justifique entrada tardía o salida temprana</strong></label>
                <select className="form-control" value={justificacion} onChange={changeJusti}>
                    <option value='Enfermedad'>Enfermedad</option>
                    <option value='Permiso aprobado JA'>Permiso aprobado JA</option>
                    <option value='Cita Medica' >Cita Medica</option>
                    <option value='Emergencia Familiar'>Emergencia Familiar</option>
                </select>
                <button type="button" className="btn btn-primary" onClick={updateJustificacion}>Justificar</button>
            </div>
            <br></br>
            <div style={{ textAlign: "center", visibility: solicitar }}>
                <button type="button" className="btn btn-link" disabled={diasDisponibles === '0' || diasDisponibles === 0} onClick={() => cuantosDias(1)}>
                    <img src="/vacacion.png" alt="vacacion" width={150} />
                </button>
                <DatePicker dateFormat='dd/MM/yyyy' title="Escoja fecha deseada" selected={startDate} onChange={(date) => setStartDate(date)} className="form-control" />
            </div>
            <div style={{ textAlign: "center" }} >
                <strong>Usted tiene {diasDisponibles} día(s) disponibles de vacacion(es)</strong>
            </div>
            <div style={{ visibility: vacas }} className="row">
                <div className="col align-self-center text-center"><label className="form-label">Cuántos días solicita?</label></div>
                <div className="col align-self-center text-center"><input type="text" className="form-control" value={numero} onChange={cambieNumero}></input></div>
                <div className="col align-self-center text-center"><button type="button" className="btn btn-primary" onClick={() => {
                    solicitarVacaciones(diasDisponibles,
                        setDiasDisponibles, setErrorMessage, startDate, cuantosDias, numero)
                }}>Solicitar</button></div>
            </div>
            <br></br>
            <br></br>
            {errorMessage && (
                <div className="error-message"><p>{errorMessage}</p></div>
            )}
            <br></br>
            <div style={{ textAlign: "center" }}>
                <button type="button" className="btn btn-danger" onClick={() => { logout() }}>Salir</button>
            </div>
        </Container>
    );
}

export default DashboardTecnico;