import React, { useEffect, useState } from 'react';
import { getServer } from '../actions/utilities';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function MantenimientoHorarios() {
  const [data, setData] = useState([]);
  const [mensaje, setMensaje] = useState('');
  const [currentHorario, setCurrentHorario] = useState({
    idhorario: '',
    nombre: '',
    inicio: '',
    final: '',
    lunes: 0,
    martes: 0,
    miercoles: 0,
    jueves: 0,
    viernes: 0,
    sabado: 0,
    domingo: 0,
  });
  const navigate = useNavigate();

  if (sessionStorage.getItem('token') === null || sessionStorage.getItem('token') === undefined) {
    navigate("/");
}

  useEffect(() => {
    fetchHorarios();
  }, []);

  const fetchHorarios = () => {
    axios.get(getServer() + '/horarios.php')
      .then(function (response) {
        setData(response.data);
      })
      .catch(function (error) {
      })
      .then(function () {
        // always executed
      });
  }

  const clearForm = event => {
    setCurrentHorario({
      idhorario: '',
      nombre: '',
      inicio: '',
      final: '',
      lunes: 0,
      martes: 0.,
      miercoles: 0,
      jueves: 0,
      viernes: 0,
      sabado: 0,
      domingo: 0,
    });
    setMensaje("");
  }

  const getDias = (lunes, martes, miercoles, jueves, viernes, sabado, domingo) => {
    let str = "";

    str += lunes === 1 || lunes === '1'? "L-" : "";
    str += martes === 1 || martes === '1'? "K-" : "";
    str += miercoles === 1 || miercoles === '1'? "M-" : "";
    str += jueves === 1 || jueves === '1'? "J-" : "";
    str += viernes === 1 || viernes === '1'? "V-" : "";
    str += sabado === 1 || sabado === '1'? "S-" : "";
    str += domingo === 1 || domingo === '1'? "D-" : "";

    return str.substring(0, str.length - 1);
  }

  const viewHorario = (event, idhorario) => {
    const c = data.filter(obj => obj.idhorario === idhorario);
    setMensaje("");

    if (c.length > 0) {
      setCurrentHorario(c[0]);
    }
  };

  const handleInputChange = (event) => {
    setMensaje("");
    const id = event.target.id;
    let value = event.target.value;

    if (id !== 'nombre' && id !== 'inicio' && id !== 'final') {
      value = event.target.checked? 1 : 0;
    }

    setCurrentHorario({ ...currentHorario, [id]: value });

    const newData = data.map(obj => {
      if (obj.idhorario === currentHorario.idhorario) {
        obj = currentHorario;
      }

      return obj;
    });

    setData(newData);

  };

  const deleteHorario = (event, id) => {
    const postData = {
      action: 'delete',
      data: id
    };

    axios.post(getServer() + '/horarios.php', postData)
      .then(function (response) {
        clearForm();
        fetchHorarios();
        setMensaje("Datos eliminados existosamente !!!");
      })
      .catch(function (error) {
        setMensaje("Error eliminando datos ");
      })
      .then(function () {
        // always executed
      });
  }

  const saveUser = event => {
    event.preventDefault();
    const postData = {
      action: '',
      data: {}
    };

    postData.action = (currentHorario.idhorario === undefined || currentHorario.idhorario === "") ? 'save' : 'update';
    postData.data = currentHorario;

    axios.post(getServer() + '/horarios.php', postData, { timeout: 2000 })
      .then(function (response) {
        clearForm();
        fetchHorarios();
        setMensaje("Datos guardados existosamente !!!");
      })
      .catch(function (error) {
        setMensaje("Error guardando datos ");
      })
      .then(function () {
        // always executed
      });
  }

  return (
    <div className="container-lg ">
      <div className="row" style={{ margin: 50 }}>
        <div className="col align-self-center text-center">
          <h3>Mantenimiento de Horarios</h3>
        </div>
      </div>
      <form onSubmit={saveUser}>
        <div className="row">
          <div className="col">
            <div className="mb-3" >
              <label className="form-label">Nombre</label>
              <input type="text" className="form-control" id="nombre" value={currentHorario.nombre} required onChange={handleInputChange} />
            </div>
            <div className="mb-3" >
              <label className="form-label">Hora Inicio</label>
              <input type="text" className="form-control" id="inicio" value={currentHorario.inicio} onChange={handleInputChange} />
            </div>
            <div className="mb-3" >
              <label className="form-label">Hora Final</label>
              <input type="text" className="form-control" id="final" value={currentHorario.final} onChange={handleInputChange} />
            </div>
          </div>
        </div>
        <div className="row">
            <div className="col form-check form-switch" style={{ marginRight: 25 }}>
              <input className="form-check-input" type="checkbox" role="switch" id="lunes" checked={currentHorario.lunes === 1 ||currentHorario.lunes === '1' } onChange={handleInputChange} />
              <label className="form-check-label" for="cbLunes">Lunes</label>
            </div>
            <div className="col form-check form-switch" style={{ marginRight: 25 }} >
              <input className="form-check-input" type="checkbox" role="switch" id="martes" checked={currentHorario.martes === 1 || currentHorario.martes === '1'} onChange={handleInputChange} />
              <label className="form-check-label" for="cbMartes">Martes</label>
            </div>
            <div className="col form-check form-switch" style={{ marginRight: 25 }} >
              <input className="form-check-input" type="checkbox" role="switch" id="miercoles" checked={currentHorario.miercoles === 1 || currentHorario.miercoles === '1'} onChange={handleInputChange} />
              <label className="form-check-label" for="cbMiercoles">Miercoles</label>
            </div>
            <div className="col form-check form-switch" style={{ marginRight: 25 }} >
              <input className="form-check-input" type="checkbox" role="switch" id="jueves" checked={currentHorario.jueves === 1 || currentHorario.jueves === '1'} onChange={handleInputChange} />
              <label className="form-check-label" for="cbJueves">Jueves</label>
            </div>
            <div className="col form-check form-switch" style={{ marginRight: 25 }} >
              <input className="form-check-input" type="checkbox" role="switch" id="viernes" checked={currentHorario.viernes === 1 || currentHorario.viernes === '1'} onChange={handleInputChange} />
              <label className="form-check-label" for="cbViernes">Viernes</label>
            </div>
            <div className="col form-check form-switch" style={{ marginRight: 25 }} >
              <input className="form-check-input" type="checkbox" role="switch" id="sabado" checked={currentHorario.sabado === 1 || currentHorario.sabado === '1'} onChange={handleInputChange} />
              <label className="form-check-label" for="cbSabado">Sabado</label>
            </div>
            <div className="col form-check form-switch" style={{ marginRight: 25 }} >
              <input className="form-check-input" type="checkbox" role="switch" id="domingo" checked={currentHorario.domingo === 1 || currentHorario.domingo === '1'} onChange={handleInputChange} />
              <label className="form-check-label" for="cbDomingo">Domingo</label>
            </div>
          <br></br><br></br>
          <div className="mb-3" align="center">
            <button type="submit" className="btn"><img alt='guardar formulario' src='/save.png' className='rounded' width="50px" /></button>
            <button type="button" className="btn"><img src='/limpiar.png' className='rounded' width="50px" alt='Limpiar formulario' title='Limpiar' onClick={clearForm} /></button>
          </div>
        </div>
      </form>
      <div className="col align-self-center text-center alert alert-secondary">
        <label>{mensaje}</label>
      </div>
      <div className="row" style={{ margin: 50 }}>
        <div className="col align-self-center text-center">
          <h3>Horarios Registrados</h3>
        </div>
      </div>
      <div className='row border border-sucess text-center' >
        <div id='header' className='col border border-sucess'>Acción</div>
        <div id='header' className='col border border-sucess'>Nombre</div>
        <div id='header' className='col border border-sucess'>Inicio</div>
        <div id='header' className='col border border-sucess'>Final</div>
        <div id='header' className='col border border-sucess'>Días</div>
      </div>
      {
        data.map(({ idhorario, nombre, inicio, final, lunes, martes, miercoles, jueves, viernes, sabado, domingo }) => (
          <div className='row'>
            <div className='col border border-sucess align-self-center text-center'>
              <a
                href='#'
                onClick={event => viewHorario(event, idhorario)}
              >
                <img src='edit.png' alt='edit button' width="25px" style={{ margin: 5 }}></img>
              </a>
              <a
                href='#'
                onClick={event => deleteHorario(event, idhorario)}
              >
                <img src='delete.png' alt='delete button' width="25px"></img>
              </a>
            </div>
            <div className='col border border-sucess'>{nombre}</div>
            <div className='col border border-sucess'>{inicio}</div>
            <div className='col border border-sucess'>{final}</div>
            <div className='col border border-sucess'>{getDias(lunes, martes, miercoles, jueves, viernes, sabado, domingo)}</div>
          </div>
        ))
      }
      <div className='align-self-center text-center'>
        <button type="button" className="btn">
          <a href='#' onClick={() => navigate("/")}>
            <img src='/back.png' className='rounded' width="50px" title='Regresar al reporte' alt='' />
          </a>
        </button>
      </div>
      <div className='row' style={{ margin: 50 }}></div>
    </div>
  );
}