import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import Dashboard from './components/Dashboard';
import MantenimientoEmpleados from './components/MantenimientoEmpleados';
import MantenimientoParametros from './components/MantenimientoParametros';
import MantenimientoHorarios from './components/MantenimientoHorarios';
import ReporteAsistencia from './components/ReporteAsistencia';
import ReporteVacaciones from './components/ReporteVacaciones';
import AprobarVacaciones from './components/AprobarVacaciones';

class App extends Component {

  render() {
    return (
      <div>
        {this.title}
        
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/login" element={<Dashboard />} />
            <Route path="/musers" element={<MantenimientoEmpleados />} />
            <Route path="/mparams" element={<MantenimientoParametros />} />
            <Route path="/mhorarios" element={<MantenimientoHorarios />} />
            <Route path="/rasistencia" element={<ReporteAsistencia />} />
            <Route path="/rvacaciones" element={<ReporteVacaciones />} />
            <Route path="/aprobarvac" element={<AprobarVacaciones />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
