import axios from "axios";

export function getServer () {
    let server = '/accesos';
  
    if (process.env.NODE_ENV === 'development') {
      server = 'http://localhost/accesos';
    }
  
    return server;
  }

  export function logout() {
    axios.post(getServer() + `/logout.php`)
        .then(res => {
            sessionStorage.clear();
            window.location ="/";
        });
}