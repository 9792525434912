import axios from "axios";
import format from "date-fns/format";
import { getServer } from "./utilities";


export function solicitarVacaciones(diasDisponibles, setDiasDisponibles, setErrorMessage, startDate, cuantosDias, numero) {

    setErrorMessage('');

    if (diasDisponibles === '0') {
        setErrorMessage('Días de vacaciones no disponibles');
        return;
    }
 
    const data = {
        'action': 'solicitar', 'token': sessionStorage.getItem('token'),
        'usuario': sessionStorage.getItem('usuario'), 'fechaActual': format(new Date(), 'yyyy-MM-dd'), 'fechaSolicitada': format(startDate, 'dd/MM/yyyy'),
        'cantidad': diasDisponibles, 'diasSolicitados': numero, 'nombre': sessionStorage.getItem('nombre')
    };

    axios.post(getServer() + `/vacaciones.php`, data)
        .then(res => {
            sessionStorage.setItem('disp', diasDisponibles - numero);
            setDiasDisponibles(diasDisponibles - numero);
            setErrorMessage('Fecha  ' + format(startDate, 'dd/MM/yyyy') + ' solicitada!!!');
            cuantosDias(2);


        });
}