import axios from "axios";
import { useEffect, useState } from "react";
import { getServer } from "../actions/utilities";
import { useNavigate } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import moment from "moment/moment";
import XLSX from 'sheetjs-style';
import FileSaver from 'file-saver';
import dayjs from "dayjs";
import './ReporteAsistencia.css';

export default function ReporteAsistencia() {

    const currentDate = new Date();
    const [data, setData] = useState([]);
    const [startDate1, setStartDate1] = useState(new Date().setDate(currentDate.getDate() - 30));
    const [startDate2, setStartDate2] = useState(new Date().setDate(currentDate.getDate() + 1));
    const navigate = useNavigate();

    if (sessionStorage.getItem('token') === null || sessionStorage.getItem('token') === undefined) {
        navigate("/");
    }

    const exportToExcel = async (excelData) => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: {'data': ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8' });
        FileSaver.saveAs(data, 'Reporte Asistencia.xlsx');
    }

    const calcularHorasTrabajadas = (entrada, salida) => {
        var date1 = dayjs(entrada);
        var date2 = dayjs(salida);
        var diff = date2.diff(date1, 'minutes') / 60;

        console.log(diff)

        return parseFloat(diff).toFixed(2);
    } 

    useEffect(() => {
        fetchReporte();
    }, []);

    const fetchReporte = () => {
        const postData = {
            d1: moment(startDate1).format('YYYY-MM-DD'),
            d2: moment(startDate2).format('YYYY-MM-DD'),
          };
        axios.post(getServer() + "/reportes.php", postData)
            .then(function (response) {
                response.data.map(e => {                              
                    e.Horas = e.salida !== null? parseFloat(parseFloat(moment(e.salida).diff(moment(e.entrada), "minutes") / 60).toFixed(2) - parseFloat(e.Extras).toFixed(2)).toFixed(2): '';
                    e.entrada = dayjs(e.entrada).format('DD-MM-YY HH:mm');
                    e.salida = e.salida !== null? dayjs(e.salida).format('DD-MM-YY HH:mm'): '';
                    e.rangofuera = e.rangofuera === 1? 'Si':'No';
                    e.rangofuera2 = e.rangofuera2 === 1? 'Si':'No';
                    e.tardia = e.tardia == 1? "Si" : "No";
                    e.Extras = parseFloat(e.Extras).toFixed(2);
                });
                setData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }

    return (
        <div className="container-lg ">
            <div className="row" style={{ margin: 50 }}>
                <div className="col align-self-center text-center">
                    <h3>Reporte de Asistencia</h3>
                </div>
            </div>
            <div className="row" style={{ margin: 15 }}>
                <div className="col">
                    <h3>Búsqueda</h3>
                </div>
                <div className="col">
                    <label>Del</label>
                </div>
                <div className="col">
                    <ReactDatePicker title="Escoja fecha deseada" selected={startDate1} onChange={(date) => setStartDate1(date)} className="form-control" />
                </div>
                <div className="col">
                    <label>al</label>
                </div>
                <div className="col">
                    <ReactDatePicker title="Escoja fecha deseada" selected={startDate2} onChange={(date) => setStartDate2(date)} className="form-control" />
                </div>
                <div className="col">
                    <button type="button" className="btn btn-primary " onClick={() => fetchReporte()} >
                        Buscar
                    </button>
                </div>
            </div>
            <hr></hr>
            <table className="table table-responsive table-striped">
                <thead className='row border border-sucess text-center' >
                    <th id='header' className='col border border-sucess'>Nombre</th>
                    <th id='header' className='col border border-sucess hide1200' >Horario</th>
                    <th id='header' className='col border border-sucess'>Entrada</th>
                    <th id='header' className='col border border-sucess'>Salida</th>
                    <th id='header' className='col border border-sucess hide700'>Tardía - Justificación</th>
                    <th id='header' className='col border border-sucess hide950'>Cordenadas Ingreso</th>
                    <th id='header' className='col border border-sucess hide950'>Cordenadas Salida</th>
                    <th id='header' className='col border border-sucess hide500'>Horas Trabajadas</th>
                    <th id='header' className='col border border-sucess hide500'>Horas Extra</th>
                </thead>
                <tbody>
                    {
                        data.map(({ Tecnico, Horario, entrada, salida, justificacion, rangofuera, rangofuera2, inicio, final, Extras, Horas }) => (
                            <tr className='row' >
                                <td className='col border border-sucess'>{Tecnico}</td>
                                <td className='col border border-sucess hide1200'>{Horario}</td>
                                <td className='col border border-sucess'>{entrada.toString()}</td>
                                <td className='col border border-sucess'>{salida.toString()}</td>
                                <td className='col border border-sucess hide700'>{justificacion}</td>
                                <td className='col border border-sucess text-center hide950'>
                                    {
                                        (rangofuera === 'No')? 
                                            <img src='/check.png' height={25} /> :
                                            <img src='/x.png' height={25}/>
                                    }
                                </td>
                                <td className='col border border-sucess text-center hide950'>
                                    {
                                        (rangofuera2 === 'No')? 
                                            <img src='/check.png' height={25} /> :
                                            <img src='/x.png'  height={25} />
                                    }
                                </td>
                                <td className='col border border-sucess hide500'>{Horas}</td>
                                <td className='col border border-sucess hide500'>{Extras}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <div className='align-self-center text-center'>
                <button type="submit" className="btn"><img src='/save.png' alt="save button" className='rounded' width="50px" onClick={() => exportToExcel(data) }/></button>
                <button type="button" className="btn">
                    <a href='#' onClick={() => navigate("/")}>
                        <img src='/back.png' className='rounded' width="50px" title='Regresar al reporte' alt='' />
                    </a>
                </button>
            </div>
        </div>

    );
}
