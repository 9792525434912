import React, { useState } from 'react';
import './LoginPage.css';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { getServer } from '../actions/utilities';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleUserChange = (event) => {
    setErrorMessage('');
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setErrorMessage('');
    setPassword(event.target.value);
  };
  
  const handleSubmit = (event) => {    
    event.preventDefault();

    if (username === '') {
      setErrorMessage('Usuario y/o clave inválidos');
      return;
    }

    axios.post(getServer() + `/processLogin.php`, { username, password, action: 'validate' } )
      .then(res => {
        const data = res.data;
        
        if (data.success === 'false'){
          setErrorMessage("Usuario y/o clave inválidos");
        }else {
          sessionStorage.setItem('token', data.token);
          sessionStorage.setItem('usuario', data.usuario);
          sessionStorage.setItem('nombre', username);
          sessionStorage.setItem('role', data.role);
          sessionStorage.setItem('disp', data.disponible);

          window.location.reload(false);
          <Navigate to="/" />
        }
      });

  };

  return (
    <div className="login-page">
      <form onSubmit={handleSubmit} className="login-form">
        <h1 className="login-title">Accesos Dashboard</h1>
        <div className="form-group">
          <label htmlFor="username" className="form-label">Usuario</label>
          <input
            type="username"
            id="username"
            className="form-input"
            value={username}
            onChange={handleUserChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password" className="form-label">Clave</label>
          <input
            type="password"
            id="password"
            className="form-input"
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
        <button type="submit" className="submit-button">Enviar</button>
        {errorMessage && (
          <div className="error-message"><p>{errorMessage}</p></div>
        )}
      </form>
    </div>
  );
};

export default LoginPage;
