import { useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { logout } from "../actions/utilities";

const DashboardAdmin = () => {
    const navigate = useNavigate();

    if (sessionStorage.getItem('token') === null) {
        navigate('/login');
    }

    const [title, setTitle] = useState((sessionStorage.getItem('token') !== null) ? <h1 style={{ textAlign: "center" }}>Sistema de Acceso</h1> : '');

    return (
        <Container>
            {title}
            <div className="container">
                <div className="row  align-items-center border">
                    <div className="col-sm">
                        <img src="/reports.png" width="200" height={200} />
                    </div>
                    <div className="col-sm">
                        <ul>
                            <li><a href="/rasistencia">Reporte Asistencia</a></li>
                        </ul>
                    </div>
                </div>
                <div className="row  align-items-center border">
                    <div className="col-sm">
                        <img src="/timeoff.png" width="200" height={200}/>
                    </div>
                    <div className="col-sm">
                        <ul>
                            <li><a href="/aprobarvac">Aprobar vacaciones</a></li>
                            <li><a href="/rvacaciones">Reporte de vacaciones</a></li>
                        </ul>
                    </div>
                </div>
                <div className="row  align-items-center border">
                    <div className="col-sm">
                        <img src="/config.png" width="200" height={200}/>
                    </div>
                    <div className="col-sm">
                        <ul>
                            <li><a href="/musers">Mantenimiento Empleados</a></li>
                            <li><a href="/mparams">Mantenimiento Coordenadas</a></li>
                            <li><a href="/mhorarios">Mantenimiento Horarios</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <br></br>
            <div className="align-self-center ">
                <button type="button" className="btn btn-primary " onClick={()=>logout()}>
                    Salir
                </button>
            </div>
        </Container>
    );
}

export default DashboardAdmin;